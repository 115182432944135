import React from 'react'
import { Link } from 'gatsby'
import config from '../../data/SiteConfig'
import Categories from './Categories'
import * as headerStyles from './Header.module.scss'

const Header = () => (
  <header>
    <h1>
      <Link to="/" activeClassName={headerStyles.activeNav}>
        {config.siteTitle}
      </Link>
    </h1>
    <nav>
      <ul className={headerStyles.mainNav}>
        <li>
          <Link to="/about" activeClassName={headerStyles.activeNav}>
            About
          </Link>
        </li>
      
        <Categories activeClassName={headerStyles.activeNav} />
        <li>
          <Link to="/contact" activeClassName={headerStyles.activeNav}>
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  </header>
)

export default Header
